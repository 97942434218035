var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { title: "条码支付", footer: null, width: 350 },
          on: { cancel: _vm.handleChose },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("div", [
            _c("p", [_vm._v("请输入用户条形码:")]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "margin-bottom": "14px"
                }
              },
              [
                _c("a-input", {
                  ref: "barCodeInput",
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleOk.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.barCodeValue,
                    callback: function($$v) {
                      _vm.barCodeValue = $$v
                    },
                    expression: "barCodeValue"
                  }
                }),
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.handleOk }
                  },
                  [_vm._v("确认支付")]
                )
              ],
              1
            ),
            _c("p", [_vm._v("或者使用(扫码枪/扫码盒)扫码:")]),
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("img", {
                attrs: { src: require("@/assets/payTestImg/scan.svg"), alt: "" }
              })
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }